<template>
  <div style="background-color: #F4F7FC;" class="padd30 mt30">
    <van-row class="mt5 mcenter">
      <van-col span="24" class="  font16">
        <p class="padd10">成都四小牛科技有限公司</p>
        <a href="https://beian.miit.gov.cn/">蜀ICP备2021025007号</a>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "newBottom"
}
</script>

<style scoped>

</style>