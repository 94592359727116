<template>
  <div :class="name == 'partners'?'colw':''">
    <van-row>
      <van-col :span="size=='mini'?7:5" :class="[size == 'mini'?'mcenter font17':'font22']" class="mt2">四小牛科技</van-col>
      <van-col :span="size=='mini'?4:3" v-for="(ik,kk) in topTab" :key="kk" class="mcenter mt5">
        <div @click="toDetail(ik)" class="text mycursor" :class="[name == ik.name?'col06':'',size == 'mini'?'mcenter font14':'font16']">{{ik.title}}</div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "newHeader",
  props: ["name","size"],
  data(){
    return{
      topTab: [
        {name: "home",title: "首页"},
        {name: "jxfwDetail",title: "建信服务"},
        {name: "sxDetail",title: "四序"},
        {name: "partners",title: "合作伙伴"},
      ],
    }
  },
  methods: {
    toDetail(ik){
      if (ik.name == "home"){
        this.$router.push({path: "/"})
      }else {
        this.$router.push(ik.name)
      }
    },
  },
}
</script>

<style scoped>
.text:hover{color: #0066ff;}
</style>