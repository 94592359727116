<template>
  <div style="position:relative;">
    <van-swipe class="my-swipe" :autoplay="5000" indicator-color="#0066ff" @change="onChange">
      <van-swipe-item v-for="(it,ke) in infoType" :key="ke">
        <div class="h100" :class="[it.name == 'sx'?'sxbg':'jxbg']">
          <div v-if="currentwidth < 720">
            <div class="bgimg bgsize2" :class="[it.name == 'sx'?'sximg1':'jximg1']" @click="toOpen(it.name)"></div>
          </div>
          <div v-else>
            <div class="bgimg bgsize1" :class="[it.name == 'sx'?'sximg':'jximg']" @click="toOpen(it.name)">
              <div class="w70 padd20">
                <!--              <van-row>
                                <van-col span="4" class="font22 col06">四小牛科技</van-col>
                                <van-col span="2" v-for="(ik,kk) in topTab" :key="kk" class="mcenter">
                                  <div @click="toDetail(ik)" class="font16">{{ik.title}}</div>
                                </van-col>
                              </van-row>-->
                <!--              <div class="ml20 mt15vh">
                                <div v-if="it.name == 'jxfw'">
                                  <div class="font46">免费下载招标文件</div>
                                  <div class="mt30">
                                    <div class="ftleft mr20" v-for="(ite,kee) in jxTab" :key="kee">
                                      <div class="ftleft tabbg mcenter"><van-image :src="ite.icon" width="30" height="34" class="mt15"/></div>
                                      <div class="ftleft ml5 font30 mt5">{{ite.title}}</div>
                                      <div class="clear"></div>
                                    </div>
                                    <div class="clear"></div>
                                  </div>
                                  <div class="mt20 col56">项目查询（立项信息/开标家数/中标企业）</div>
                                  <div class="mt10 col56">企业查询（企业大数据/业主大数据/代理大数据）</div>
                                  <div class="mt30">&lt;!&ndash;linear-gradient(to right, #0066ff, #5A69FF)"&ndash;&gt;
                                    <div class="ftleft"><van-image :src="require('@/assets/img/jxfw.png')" width="170" height="170"/></div>
                                    <div class="ftleft button col06 mt60 ml20" @click="toPreview">立即扫码体验 > </div>
                                    <div class="clear"></div>
                                  </div>
                                </div>
                                <div v-if="it.name == 'sx'">
                                  <div class="font46">伴随企业成长的<p>业务管理平台</p></div>
                                  <div class="font26 mt20"><span class="font30 col06">无代码</span>搭建平台，灵活定制你的需求</div>
                                  <div class="mt60"><van-button round color="#0066ff" @click="toOpen"><span class="paddzy20">申请试用</span></van-button></div>
                                </div>
                              </div>-->
              </div>
            </div>
          </div>

        </div>
      </van-swipe-item>
    </van-swipe>
    <div style="position: fixed;top: 0;width: 100vw;">
      <div v-if="currentwidth < 720">
        <div class="padd20 mr10">
          <new-header :size="'mini'" :name="'home'"></new-header>
        </div>
      </div>
      <div v-else>
        <div class="w70 padd20">
          <new-header :name="'home'" :size="'large'"></new-header>
        </div>
      </div>
    </div>
    <div style="position: fixed;bottom: 0;background: rgba(255,255,255,0.3);width: 100vw;" class="mycursor">
      <van-row>
        <van-col span="12" class="mright ">
          <div class="padd25 bbg" @click="toDetail('jxfwDetail')">
            <div style="border-right: 1px solid #d7d7d7">
              <div v-if="currentwidth < 430" class="paddzy10">
                <div>
                  <span style="vertical-align: -50%"><van-image :src="require('@/assets/img.png')" round width="35" height="35" /></span>
                  <span class="ml5 font20">建信服务</span>
                </div>
                <p class="mt5 font14 ">文件下载&数据分析&信息查询&工程交流圈</p>
              </div>
              <div v-else>
                <div class="ftright" :class="[currentwidth>1000?'mr50':(currentwidth<375?'mr5':'mr20')]">
                  <div class="font20 mleft">建信服务</div>
                  <div class="mt5 font14">
                    <div v-if="currentwidth > 720">文件下载&数据分析&信息查询&工程交流圈</div>
                    <div v-else><div>文件下载&数据分析&</div><div>信息查询&工程交流圈</div></div>
                  </div>
                </div>
                <div class="ftright mr5">
                  <van-image :src="require('@/assets/img.png')" round width="50" height="50" />
                </div>
                <div class="clear"></div>
              </div>
            </div>
          </div>
        </van-col>
        <van-col span="12">
          <div class="padd25 bbg" @click="toDetail('sxDetail')">
            <div v-if="currentwidth < 430" class="paddzy10">
              <div>
                <span style="vertical-align: -50%"><van-image :src="require('@/assets/sixu.png')" round width="35" height="35" /></span>
                <span class="ml5 font20">四序</span>
              </div>
              <div class="mt5 font14 ">应用可视化搭建，定制伴随企业成长管理系统</div>
            </div>
            <div v-else>
              <div class="ftleft mr5" :class="[currentwidth>1000?'ml50':(currentwidth<700?'ml10':'ml20')]">
                <van-image :src="require('@/assets/sixu.png')" round :width="55" :height="55" />
              </div>
              <div class="ftleft">
                <div class="font20">四序</div>
                <div class="mt5 font14">
                  <div v-if="currentwidth > 720">应用可视化搭建，定制伴随企业成长管理系统</div>
                  <div v-else><div>应用可视化搭建，定制</div><div>伴随企业成长管理系统</div></div>
                </div>
              </div>
              <div class="clear"></div>
            </div>

          </div>
        </van-col>
      </van-row>
      <div class="padd10 mcenter"><span class="font16 mr50">四小牛科技有限公司</span><a href="https://beian.miit.gov.cn/">蜀ICP备2021025007号</a></div>
    </div>
    <div style="position:fixed;right: 0;top: 30vh;z-index: 99;"><van-image :src="require('@/assets/img/kefu.gif')" :width="currentwidth<720?60:105" :height="currentwidth<720?60:105" /></div>
    <div style="position:fixed;z-index: 98;" :style="{right: currentwidth<720?'13px':'23px',top: currentwidth<720?'33vh':'35vh'}">
      <div style="border-radius: 25px;box-shadow: 0 2px 2px 2px #e1e1e1" :style="{width: currentwidth< 720?'35px':'60px',padding: currentwidth< 720?'10px 0':'30px 0'}" class="bgwhite mcenter">
        <div class="padd15"></div>
        <div :class="[currentwidth<720?'mb15':'mb30']" v-for="(ik,kk) in kefuType" :key="kk">
          <van-popover v-model="ik.show" placement="left" trigger="click" @open="toShow(kk)" theme="dark">
            <div class="p10" v-if="ik.name == 'phone'">
              <div>联系电话</div>
              <div class="mt10">18683523881</div>
            </div>
            <div v-else-if="ik.name == 'wx'" class="p10">
              <van-image :src="require('@/assets/sixu2.png')" height="200" width="200"></van-image>
            </div>
            <div v-else-if="ik.name == 'gzh'" class="p10 mcenter">
              <div class="mb10 font17">四序公众号（微信扫一扫）</div>
              <van-image :src="require('@/assets/sixuma.png')" height="200" width="200"></van-image>
              <div class="mb10 mt15 font17">建信服务公众号（微信扫一扫）</div>
              <van-image :src="require('@/assets/jxfw.jpg')" height="200" width="200"></van-image>
            </div>
            <template #reference>
              <div v-if="currentwidth< 720">
                <van-image :src="ik.img" width="25" height="25" />
              </div>
              <div v-else class="mycursor"><van-image :src="ik.img" width="38" height="38" /></div>
            </template>
          </van-popover>
        </div>
<!--        <div class="mt30" @mouseenter="toShow('phone')" @mouseleave="toClose"><van-image :src="require('@/assets/img/wx.png')" width="38" height="38" /></div>
        <div class="mt30" @mouseenter="toShow('phone')" @mouseleave="toClose"><van-image :src="require('@/assets/img/gzh.png')" width="38" height="38" /></div>-->
      </div>
    </div>
<!--    <div>
      <new-bottom></new-bottom>
    </div>-->
    <van-dialog v-model="show" title="四序客服" class="mcenter" confirm-button-color="#0066ff" confirmButtonText="关闭">
      <van-image :src="require('@/assets/sixu2.png')" height="200" width="200" class="padd20"></van-image>
      <div class="paddbot10 col6">{{ currentwidth < 400 ? "长按识别途中二维码" : "微信扫一扫" }}</div>
<!--      <van-cell-group>
        <van-field v-model="form.nickName" label="姓名" placeholder="请输入姓名" />
        <van-field v-model="form.phone" label="联系方式" placeholder="请输入联系方式" />
      </van-cell-group>-->
    </van-dialog>
    <van-dialog v-model="showImg" title="建信服务" class="mcenter" confirm-button-color="#0066ff" confirmButtonText="关闭">
      <div class="p20"><van-image :src="require('@/assets/img/jxfw.png')" height="200" width="200"></van-image></div>
      <div class="padd15 mcenter font14 col6">{{currentwidth < 400?"长按图片识别小程序":"微信扫一扫"}}</div>
      <div></div>
    </van-dialog>
<!--    <van-image-preview v-model="showImg" :images="images" @change="onChange" clearable>
      <div class="bgwhite"></div>
    </van-image-preview>-->
  </div>
</template>

<script>
import {ImagePreview, Toast} from "vant";
import newHeader from "@/components/newHeader"
import newBottom from "@/components/newBottom"
import qs from "qs";
import axios from "axios";

export default {
  name: "newHome",
  components: {newHeader,newBottom},
  data(){
    return{
      active: "jxfw",
      infoType: [
        {name: "jxfw",icon: "@/assets/img.png",bg: "@/assets/img/homeJX.png",title: "建信服务",context: "文件下载&数据分析&信息查询&工程交流圈"},
        {name: "sx",icon: "@/assets/sixu.png",bg: "@/assets/img/homeSX.png",title: "四序无代码平台",context: "应用可视化搭建，定制伴随企业成长管理系统"},
      ],
      topTab: [
        {name: "home",title: "首页"},
        {name: "jxfw",title: "建信服务"},
        {name: "sx",title: "四序"},
        {name: "partners",title: "合作伙伴"},
      ],
      jxTab: [
        {title: "清单",icon: require('@/assets/img/invent.png')},{title: "图纸",icon: require("@/assets/img/drawing.png")},{title: "补遗",icon: require("@/assets/img/by.png")}
      ],
      kefuType: [{img: require('@/assets/img/phone.png'),name: 'phone',show: false,},{img: require('@/assets/img/wx.png'),name: "wx",show: false,},{img: require('@/assets/img/gzh.png'),show: false,name: 'gzh'}],
      type: "",
      show: false,
      form: {
        nickName: "",
        phone: "",
      },
      currentwidth: 1000,
      showImg: false,
      images: [require('@/assets/img/jxfw.png')],
      userAgent: "",
      urllink: "",
    }
  },
  mounted() {
    this.initData();
    let that = this;
    that.currentwidth = window.document.body.offsetWidth;
    window.onresize = () => {
      that.currentwidth = window.document.body.offsetWidth;
    }
    this.isMobileDevice()
    console.log("width",this.currentwidth)
  },
  methods: {
    isMobileDevice() {
      let userAgent = navigator.userAgent || navigator.vendor || window.oper
      console.log("userAgent",userAgent)
    },
    initData(){
      this.active = "jxfw";
      this.type = "";
      this.show = false;
      this.form = {
        nickName: "",
        phone: "",
      };
      this.showImg = false;
    },
    toDetail(name){
      this.$router.push(name)
    },
    toSubmit(){
      if (this.form.nickName && this.form.phone){
        let det = /(1[0-9]{10})|([0-9]{3,4})?[0-9]{7,8}/g;
        if (det.test(this.form.phone)){

        }else {
          Toast("请输入正确的联系方式")
        }
      }else {

      }
    },
    toOpen(name){
      if (name == "sx"){
        this.show = true;
      }else {
        let that = this;
        let userAgent = navigator.userAgent;
        let isAndroid = /Android/i.test(userAgent);
        let isiOS = /(iPhone|iPad|iPod|iOS)/i.test(userAgent);
        that.userAgent = userAgent;
        //console.log("设备",userAgent)
        if (isAndroid || isiOS){
          if (that.urllink){
            window.location.href = that.urllink;
          }else {
            that.getlink()
          }

        }else {
          that.showImg = true;
        }
      }
    },
    getlink(){
      let id = "";
      let data = {
        code: "",
        p: "",
      }
      //window.location.href = "https://sixu.work/jxfw?c=lidongyang&p=sj"
      if (window.location.href.indexOf("c=") != -1){
        id = qs.parse(window.location.href.split("#")[0].split("?")[1]).c;
        data.code = id;
        let path = qs.parse(window.location.href.split("#")[0].split("?")[1]).p;
        if (path == "sj"){
          data.p = "pages/program/index/main"
        }
      }
      //console.log("1234",data)
      let url = "https://jz.4lambs.com:443/jxfw/share/sellurl";
      axios.post(url,data).then(res => {
        if (res != null && res.data != null && res.data.content != null){
          this.urllink = res.data.content;
          //console.log("link---",this.urllink)
          window.location.href = res.data.content
        }else {
          Toast("获取失败")
        }
      })
    },
    onChange(e){
      //console.log("index",e)
      if(e == 0){
        this.active = "jxfw"
      }else {
        this.active = "sx"
      }
    },
    toPreview(){

      /*ImagePreview({images: [require('@/assets/img/jxfw.png')],closeable: true,overlayStyle: {background: 'rgba(255,255,255,0.8)'}})*/
    },
    toShow(kk){
      console.log("ik---e",kk)
      //this.type = name;
      if (kk != 0){
        this.$set(this.kefuType[0],"show",false);
      }
      if (kk != 1){
        this.$set(this.kefuType[1],"show",false);
      }
      if (kk != 2){
        this.$set(this.kefuType[2],"show",false);
      }
    },
    toClose(){
      this.show = false;
      this.type = "";
    },
  },
}
</script>

<style scoped>
.jxbg{background: linear-gradient(124deg, #DCEBFE, #E7ECFF);}
.sxbg{background: linear-gradient(124deg, #BDD9FF, #D3E8FF);}
.bgimg{background-position: right;background-repeat: no-repeat;}
.bgsize1{height: 96vh;background-size: 100% auto;}
.bgsize2{height: 600px;background-size: contain;}
.jximg{background-image: url("../../assets/img/homeJX.png")}
.sximg{background-image: url("../../assets/img/homeSX.png")}
.jximg1{background-image: url("../../assets/img/homeJX1.png")}
.sximg1{background-image: url("../../assets/img/homeSX1.png")}
.mt15vh{margin-top: 15vh;}
.tabbg{width: 64px;height: 64px;border-radius: 50%;background: #C6DDFE;}
.button{border: 1px solid #0066ff;border-radius: 30px;padding: 10px 25px;}
.bbg:hover{background: rgba(255,255,255,0.5);}
</style>